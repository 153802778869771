import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#2e2e38',
        secondary: '#c4c4cd',
        success: '#2db757',
        info: '#27ACAA',
        error: '#F95D54',
        warning: '#fb8c00',
        gray01: '#747480',
        gray02: '#9e9e9e',
        gray03: '#c4c4cd',
        offwhite: '#F6F6FA',
        offblack: '#2e2e38',
        // reddark: '#B9251C',
        redlight: '#F95D54',
        yellow: '#FFE600',
        green: '#34C768',
        greenbg: '#8CE8AD',
        greendark: '#13652A',
        redbg: '#FF9A91',
        reddark: '#7A130D',
        orange: 'FF6D00',
        orangedark: '#BC2F00',
        orangelight: 'FFB46A'
      }
    }
  }
})
