<template>
  <v-snackbar
    :color="color"
    light
    :timeout="timer"
    v-model="isVisible"
    :min-width="minWidth"
    bottom
    right
    class="justify-center"
  >
    <v-icon left>{{ icon }}</v-icon
    ><span class="text-caption">{{ message }}</span>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackbarComponent",
  data() {
    return {
      isVisible: false,
      message: "",
      color: "success",
      icon: "mdi-check",
      timer: 5000,
      minWidth: 300,
    };
  },
  methods: {
    showSnackbar(data) {
      this.message = data.message || "Something went wrong";
      this.color = data.color || "success";
      this.timer = data.timer || 5000;
      this.icon = data.icon || "mdi-check";
      this.isVisible = true;
      this.minWidth = data.minWidth || 300;
    },
  },
};
</script>
