import { msalInstance, state, loginRequest } from "./msalConfig"
import { HTTP_USER } from "./../functions/http-common";

export function useAuth() {

    const login = async() => {
        try {
            if(!msalInstance) {
                throw new Error ('MSAL not initialized. Call initializedMsal() before using MSAL API.')
            }
            await msalInstance.loginRedirect(loginRequest)
            state.isAuthenticated = true
        } catch (error) {
            console.log('Login error:', error)
        }
    }

    const logout = async() => {
        if(!msalInstance) {
            throw new Error ('MSAL not initialized. Call initializedMsal() before using MSAL API.')
        }
        await msalInstance.logoutRedirect()
        state.isAuthenticated = false
        console.log('Logged out')
    }

    const handleRedirect = async () => {
        try {
            await msalInstance.handleRedirectPromise()
            const accounts = msalInstance.getAllAccounts()[0];
            if(accounts && Object.keys(accounts).length === 0) {
                login()
            } else {
                state.isAuthenticated = true
            }
        } catch(error) {
            console.log('Redirect error: ', error)
        }
    }

    const getUserList = async () => {
        return HTTP_USER.get('/user/me').then(response => {
        state.user = response.data
        })
    }

    return { login, logout, handleRedirect, getUserList }
}