import { settingList } from "../data/constant";

class Excel {
  constructor() {
    this.oldStatusTracker = {};
  }

  splitExcelCell(cell) {
    let match = cell.match(/^([A-Z]+)(\d+)$/);
    if (match) {
      return { column: match[1], row: parseInt(match[2]) };
    } else {
      console.log("Invalid cell reference");
    }
  }

  isRangeWithin(targetRangeAddress, rangeAddress) {
    // Logic to check if rangeAddress falls within targetRangeAddress
    return rangeAddress.includes(targetRangeAddress);
  }

  async deleteNamesWithinRange(targetRangeAddress, context) {
    const namedItems = context.workbook.names.load("items");
    await context.sync();

    let itemsToDelete = [];

    for (let namedItem of namedItems.items) {
      if (
        this.isRangeWithin(
          targetRangeAddress,
          this.removeDollarAfterLastExclamation(
            JSON.stringify(namedItem.value),
          ),
        )
      ) {
        itemsToDelete.push(namedItem);
      }
    }

    itemsToDelete.forEach((item) => item.delete());
  }
  getResultsById(results) {
    let resultData = {};
    let itemIds = {};
    settingList.forEach((item) => {
      const pipeline = item.type;
      const matchingResults = (results && results[pipeline]) || [];
      let lastResult =
        matchingResults &&
        matchingResults.filter(
          (item) => item.status === "finished" || item.status === "uploaded",
        )[0];
      if (lastResult && Object.keys(lastResult).length) {
        let lastID = lastResult.id;
        let lastAnswer =
          (lastResult.response && lastResult.response.answer) || "";
        resultData = {
          ...resultData,
          [pipeline]: lastAnswer || "",
        };
        itemIds = {
          ...itemIds,
          [pipeline]: lastID || null,
        };
      } else {
        resultData = {
          ...resultData,
          [pipeline]: "No result",
        };
        itemIds = {
          ...itemIds,
          [pipeline]: null,
        };
      }
    });
    return { resultData, itemIds };
  }

  getQnAById(results) {
    let resultData = {};
    let itemIds = {};

    const answerPipeline = "answerLineQuestions";
    const questionPipeline = "lineQuestions";
    const managementAnswerPipeline = "managementAnswers"
    settingList.forEach((item) => {
      const pipeline = item.type;

      if (pipeline === answerPipeline || pipeline === questionPipeline) {
        const matchingResults = (results && results[questionPipeline]) || [];
        const lastResult =
          matchingResults &&
          matchingResults.filter(
            (item) => item.status === "finished" || item.status === "uploaded",
          )[0];
        if (lastResult && Object.keys(lastResult).length) {
          const lastID = lastResult.id;
          const extractionResult = lastResult.extractions || [];

          resultData[questionPipeline] = [];
          itemIds[questionPipeline] = [];
          resultData[answerPipeline] = [];
          itemIds[answerPipeline] = [];
          resultData[managementAnswerPipeline] = []
          itemIds[managementAnswerPipeline] = []
          extractionResult.forEach((extraction) => {
            let adjustedExtraction = {}
            if(extraction.adjustments && extraction.adjustments.length) {
              adjustedExtraction = extraction.adjustments[extraction.adjustments.length - 1]
            }
            const extractionId = extraction.extractionId || null;
            const extractionQuestion = (adjustedExtraction && adjustedExtraction.adjustment) || extraction.extraction || "";
            const extractionQuestionKey = `${lastID}_${extractionId}`;

            resultData[questionPipeline].push(extractionQuestion);
            itemIds[questionPipeline].push(extractionQuestionKey);
            
            // Get corresponding answer to latest question (if available)
            let answers = {
              [answerPipeline]: [],
              [managementAnswerPipeline]: []
            }
              if (extraction && extraction.answer && extraction.answer.length) {
              extraction.answer.filter((answer) => {
                if(answer.status === 'finished') {
                  answers = {
                    ...answers,
                    [answerPipeline]: [...answers[answerPipeline], answer]
                  }
                } else if (answer.status === 'uploaded') {
                  answers = {
                    ...answers,
                    [managementAnswerPipeline]: [...answers[managementAnswerPipeline], answer]
                  }
                  }
                })
              }
              Object.keys(answers).forEach((item) => {
                if(item && answers[item].length) {
                  const matchingAnswer = answers[item].slice(-1);
                  const answerResultId = matchingAnswer[0].resultId || null;
                  const extractionAnswerKey = `${answerResultId}_${extractionId}`;

                  if (matchingAnswer.length === 1) {
                  // Find the index in `matchingAnswer.fetchedQueueResults` where the `extractionId` matches
                  let adjustedExtraction = {}
            if(matchingAnswer[0].adjustments && matchingAnswer[0].adjustments.length) {
              adjustedExtraction = matchingAnswer[0].adjustments[matchingAnswer[0].adjustments.length - 1]
            }
                    const extractionAnswer =
                      (adjustedExtraction && adjustedExtraction.adjustment) || matchingAnswer[0].extraction || "";
                    const extractionAnswerCleaned = extractionAnswer
                      .replace(/\[A\]\n/, "")
                      .replace(/\[A\]/, "")
                      .replace(/\n\[\/A\]/, "")
                      .replace(/\[\/A\]/, "");
                    resultData[item].push(extractionAnswerCleaned);
                    itemIds[item].push(extractionAnswerKey);
                  } else {
                    // Handle case when no matching extractionId is found in fetchedQueueResults
                    console.log(
                      `Question without answer - No matching extractionId found in fetchedQueueResults for question ${extractionQuestionKey}`,
                    );
                    if (item !== managementAnswerPipeline) itemIds[item].push(null);
                    else itemIds[managementAnswerPipeline].push(`answer_${extractionId}_${lastResult.rowId}_${lastResult.id}`);
                    resultData[item].push("");
                    
                  }
                } else {
                resultData[item].push("");
                if (item !== managementAnswerPipeline) itemIds[item].push(null);
                else itemIds[managementAnswerPipeline].push(`answer_${extractionId}_${lastResult.rowId}_${lastResult.id}`);
              }
            });
          });
          if (!extractionResult.length) {
            resultData[questionPipeline].push("");
            itemIds[questionPipeline].push(null);

            resultData[answerPipeline].push("");
            itemIds[answerPipeline].push(null);

            resultData[managementAnswerPipeline] = "";
            itemIds[managementAnswerPipeline] = null;
          }
          
        } else {
          resultData[questionPipeline] = "";
          itemIds[questionPipeline] = null;

          resultData[answerPipeline] = "";
          itemIds[answerPipeline] = null;

          resultData[managementAnswerPipeline] = "";
          itemIds[managementAnswerPipeline] = null;
        }
      } else {
        resultData[pipeline] = [""];
        itemIds[pipeline] = [null];
      }
    });
    return { resultData, itemIds };
  }

  flatQnAWithIds(data, namedItemIds) {
    let flattenedData = [];
    let flattenedIds = [];

    data.forEach((item, indexB) => {
      const cloneItem = [...item]
      const [managementAnswerList, answerList, questionList, id, ...rest] = [ cloneItem.pop(), cloneItem.pop(), cloneItem.pop(), cloneItem.pop(), ...cloneItem ]

      const itemIds = namedItemIds[indexB] || {};
      const descriptionIds = itemIds["lineDescription"] || [];
      const questionIds = itemIds["lineQuestions"] || [];
      const answerIds = itemIds["answerLineQuestions"] || [];
      const commentaryIds = itemIds["lineCommentary"] || [];
      const managementAnswersIds = itemIds["managementAnswers"] || [];
      // Check if 'questionList' is an array
      if (Array.isArray(questionList)) {
        questionList.forEach((question, indexA) => {
          let answer = answerList[indexA] || "";
          let managementAnswer = managementAnswerList[indexA]
          // A new row for each question and answer pair
          let newData = [...rest, id, question, answer, managementAnswer];
          let newId = {
            lineDescription: descriptionIds[indexA] || null,
            lineQuestions: questionIds[indexA] || null,
            answerLineQuestions: answerIds[indexA] || null,
            lineCommentary: commentaryIds[indexA] || null,
            managementAnswers: managementAnswersIds[indexA] || null,
          };

          flattenedData.push(newData);
          flattenedIds.push(newId);
        });
      } else {
        // If 'questionList' is not an array, still add it to flattenedData and flattenedIds.
        flattenedData.push([
          ...rest,
          id,
          questionList,
          answerList,
          managementAnswerList,
        ]);
        flattenedIds.push({
          lineDescription: descriptionIds,
          lineQuestions: questionIds,
          answerLineQuestions: answerIds,
          lineCommentary: commentaryIds,
          managementAnswers: managementAnswersIds,
        });
      }
    });

    return { flattenedData, flattenedIds };
  }

  async processNamedItems(
    configSelection,
    namedItemIds,
    range,
    rowCount,
    tableId,
    context,
    pasteMethod,
    sheetName,
  ) {
    let isQuestionAnswer = false;
    for (const entry of Object.entries(configSelection)) {
      for (let i = 0; i <= rowCount - 1; i++) {
        const [key, value] = entry;
        if (value && namedItemIds[i][key]) {
          if(namedItemIds[i][key] && namedItemIds[i][key].length) {
            if(i) { isQuestionAnswer = true }
            await this.processName(
              tableId,
              namedItemIds[i][key],
              range,
              i,
              key,
              context,
              pasteMethod,
              sheetName,
            );
          }
        }
      }
    }
    if (!isQuestionAnswer && pasteMethod === 'qnaSheet') {
      let sheet = context.workbook.worksheets.getItem(sheetName);
      range.clear();
      sheet.delete();
      console.log("No questions nor answers generated yet.");
      throw ('No questions nor answers generated yet.');
    }
    await context.sync();
  }

  getTextBeforeLastExclamation(str) {
    const lastExclamationIndex = str.lastIndexOf("!");
    if (lastExclamationIndex !== -1) {
      return str.substring(0, lastExclamationIndex);
    }
    return str; // Return the original string if no "!" is found
  }

  removeQuotes(str) {
    return str.replace(/^'|'$/g, "");
  }

  removeDollarAfterLastExclamation(str) {
    let index = str.lastIndexOf("!");
    let result =
      str.substring(0, index + 1) + str.substring(index + 1).replace(/\$/g, "");
    return result;
  }

  async processName(
    tableId,
    resultId,
    range,
    rowIndex,
    columnName,
    context,
    pasteMethod,
    sheetName,
  ) {
    let headerIndex = range.values[0].indexOf(columnName);
    let cell = range.getCell(rowIndex, headerIndex);
    let name = "";
    if (resultId.toString().startsWith("_")) {
      name = `${resultId.toString().replace(/-/g, "")}`;
    } else {
      name = `result_${tableId}_${resultId.toString().replace(/-/g, "")}`;
    }
    if (pasteMethod === "qnaSheet") {
      let sheet = context.workbook.worksheets.getItem(sheetName);
      let nameItem = sheet.names.getItemOrNullObject(name);
      context.load(nameItem, "isNullObject");
      await context.sync();
      if (nameItem.isNullObject) {
        sheet.names.add(name, cell);
      } else {
        if (resultId.toString().startsWith("_")) {
          console.log(
            `Generated results already stored at named excel cell: ${name}`,
          );
        }
      }
      range.format.autofitRows();
      range.format.autofitColumns();
    } else {
      let nameItem = context.workbook.names.getItemOrNullObject(name);
      context.load(nameItem, "isNullObject");
      await context.sync();
      if (nameItem.isNullObject) {
        context.workbook.names.add(name, cell);
      } else {
        if (resultId.toString().startsWith("_")) {
          console.log(
            `Generated results already stored at named excel cell: ${name}`,
          );
        } else {
          range.clear();
        }
      }
    }
  }

  numToLetters(num) {
    let letters = "";
    while (num > 0) {
      let remainder = (num - 1) % 26;
      letters = String.fromCharCode(65 + remainder) + letters;
      num = Math.floor((num - remainder) / 26);
    }
    return letters;
  }

  letterToNum(letter) {
    letter = letter.toUpperCase();
    let num = 0;
    let len = letter.length;
    for (let pos = 0; pos < len; pos++) {
      num *= 26;
      num += letter.charCodeAt(pos) - 64;
    }
    return num;
  }

  getTextAfterLastExclamation(str) {
    const lastExclamationIndex = str.lastIndexOf("!");
    if (lastExclamationIndex !== -1) {
      return str.substring(lastExclamationIndex + 1);
    }
    return ""; // Return an empty string if no "!" is found
  }

  async setBorderStyle(sheetName, rangeAddress, borderStyles, context) {
    let sheet = context.workbook.worksheets.getItem(sheetName);
    let range = sheet.getRange(rangeAddress);
    range.load(["rowCount", "columnCount"]);

    await context.sync();
    const rowCount = range.rowCount;
    const columnCount = range.columnCount;

    for (let i = 0; i < rowCount; i++) {
      for (let j = 0; j < columnCount; j++) {
        const sourceIndex = i * columnCount + Math.min(j, columnCount - 1);

        let cell = range.getCell(i, j);
        let borders = cell.format.borders;
        let borderStyle = borderStyles[sourceIndex];

        for (let edge in borderStyle) {
          let border = borders.getItem(edge);
          border.color = borderStyle[edge].color;
          border.style = borderStyle[edge].style;
          border.weight = borderStyle[edge].weight;
        }
        await context.sync();
      }
    }
    await context.sync();
  }

  async copyBorderStyle(
    sourceSheetName,
    sourceRangeAddress,
    targetSheetName,
    targetRangeAddress,
  ) {
    return window.Excel.run(async (context) => {
      let borderStyles = await this.getBorderStyle(
        sourceSheetName,
        sourceRangeAddress,
        context,
      );
      await this.setBorderStyle(
        targetSheetName,
        targetRangeAddress,
        borderStyles,
        context,
      );
    });
  }

  async getBorderStyle(sheetName, rangeAddress, context) {
    let sheet = context.workbook.worksheets.getItem(sheetName);
    let range = sheet.getRange(rangeAddress);

    let borderStyles = [];
    let borderEdges = [
      "EdgeTop",
      "EdgeBottom",
      "EdgeLeft",
      "EdgeRight",
      "InsideVertical",
      "InsideHorizontal",
      "DiagonalDown",
      "DiagonalUp",
    ];

    range.load(["rowCount", "columnCount"]);
    await context.sync();

    const rowCount = range.rowCount;
    const columnCount = range.columnCount;

    for (let i = 0; i < rowCount; i++) {
      for (let j = 0; j < columnCount; j++) {
        let cell = range.getCell(i, j);
        let borderStyle = {};
        let cellBorders = cell.format.borders;
        for (let edge of borderEdges) {
          let border = cellBorders.getItem(edge);
          border.load(["color", "style", "weight"]);

          await context.sync();

          if (border.style !== "None") {
            borderStyle[edge] = {
              color: border.color,
              style: border.style,
              weight: border.weight,
            };
          }
        }
        borderStyles.push(borderStyle);
      }
    }
    console.log(borderStyles);

    return borderStyles;
  }

  async getExcelValuesAndComments(resultId, context = false, sheet = false) {
    let resultObject = {};
    if (!sheet && !context) {
      return window.Excel.run(async (context) => {
        let resultObject = context.workbook.names.getItemOrNullObject(resultId);
        let itemInExcel = resultObject.getRange();
        resultObject.load("comment");
        itemInExcel.load(["values", "address"]);
        await context.sync();

        // Prepare an object to store values and comments
        let rangeInfo = {
          values: itemInExcel.values,
          address: itemInExcel.address,
          comments: resultObject.comment,
        };
        return rangeInfo;
      }).catch(console.error);
    } else if (sheet && context) {
      
      try {
        resultObject = sheet.names.getItemOrNullObject(resultId);
        let  itemInExcel = resultObject.getRange();
        resultObject.load("comment");
        itemInExcel.load(["values", "address"]);
        await context.sync()
        // Prepare an object to store values and comments
        let rangeInfo = {
          values: itemInExcel.values,
          address: itemInExcel.address,
          comments: resultObject.comment,
        };
      return rangeInfo;
    } catch(e) {
      console.log('Deleted')
    }      
    }
  }

  async copyCellFormats(
    rowCount,
    destColCount,
    destSheetName,
    pasteRange,
    additionalColumns,
    config,
    metadata,
  ) {
    // Define array to hold formatting information
    let sourceFormats = [];

    // Retrieve source table formatting or, if error, retrieve TD default table formatting
    await window.Excel.run(async (context) => {
      try {
        // Try to get source sheet
        const sourceSheet = context.workbook.worksheets.getItem(
          metadata.sheetName,
        );
        const sourceStart = metadata.uploadRange.split(":")[0];

        // Define source table range
        const sourceRowCount = rowCount;
        const sourceColCount = destColCount - 1 - additionalColumns;

        // Get source table ranges and formats for each cell in source.
        for (let row = 0; row < sourceRowCount; row++) {
          for (let col = 0; col < sourceColCount; col++) {
            const sourceCell = sourceSheet
              .getRange(sourceStart)
              .getOffsetRange(row, col);

            // Extract cell properties
            const sourceProperties = sourceCell.getCellProperties({
              address: true,
              format: {
                fill: {
                  color: true,
                },
                font: {
                  color: true,
                  name: true,
                  size: true,
                  bold: true,
                },
                columnWidth: true,
                rowHeight: true,
              },
              style: true,
            });
            await context.sync();

            // Extract relevant properties and store to array
            let cellProperties = sourceProperties.value[0][0];
            sourceFormats.push(cellProperties);
          }
        }
        // console.log("Source table formatting retrieved.");
      } catch (error) {
        const sourceColCount = destColCount - 1 - additionalColumns;

        // If getItem fails, populate sourceFormats with default values
        for (let row = 0; row < rowCount; row++) {
          for (let col = 0; col < sourceColCount; col++) {
            sourceFormats.push({
              format: {
                fill: { color: row === 0 ? "#2E2E38" : "#FFFFFF" },
                font: {
                  color: row === 0 ? "#FFFFFF" : "#2E2E38",
                  name: "Arial",
                  size: 8,
                  bold: row === 0 ? true : false,
                },
                columnWidth: 50,
                rowHeight: 13,
              },
              borderStyle:
                row === 0
                  ? {
                      EdgeBottom: {
                        color: "#D9D9D9",
                        style: "Continuous",
                        weight: "Thin",
                      },
                    }
                  : {
                      EdgeBottom: {
                        color: "#D9D9D9",
                        style: "Continuous",
                        weight: "Thin",
                      },
                      EdgeTop: {
                        color: "#D9D9D9",
                        style: "Continuous",
                        weight: "Thin",
                      },
                    },
            });
          }
        }
        console.log(
          "Default table formatting retrieved. 'Try' failed, 'catch' triggered.",
        );
      }
    });

    // Apply retrieved formatting to destination table range
    await window.Excel.run(async (context) => {
      const destSheet = context.workbook.worksheets.getItem(destSheetName);

      // Apply the formatting of each source cell to each destination cell in range
      const range = destSheet.getRange(pasteRange);
      const sourceColCount = destColCount - 1 - additionalColumns;
      let destFormatting = [];

      for (let i = 0; i < rowCount; i++) {
        destFormatting[i] = [];

        for (let j = 0; j < destColCount; j++) {
          const sourceIndex =
            i * sourceColCount + Math.min(j, sourceColCount - 1);

          // Formatting cells independently: results columns (j >= sourceColCount) and header (i === 0)
          destFormatting[i][j] = {
            format: {
              fill: {
                color:
                  i === 0 && j >= sourceColCount
                    ? "#2E2E38"
                    : "#C4C4CD"
              },
              font: {
                color:
                  i === 0 && j >= sourceColCount
                    ? "#F6F6FA"
                    : "#2E2E38",
                name: "Arial",
                size: i == 0 ? 9 : 8,
                bold: i === 0 ? true : false,
              },
              columnwidth:
                j >= sourceColCount
                  ? 100
                  : sourceFormats[sourceIndex].format.columnWidth,
              rowHeight: sourceFormats[sourceIndex].format.rowHeight,
            },
          };
        }
      }

      if (config.method == "newSheet") {
        range.setCellProperties(destFormatting);
      }

      if (config.method == "qnaSheet") {
        range.setCellProperties(destFormatting);
        range.format.wrapText = true;

        await this.setLastColumnsWidth(context, range, 600, 3);
        await this.setLastColumnsWidth(context, range, 150, 1);
      }

      if (config.method == "nextTable" || config.method === "selectedRange") {
        range.setCellProperties(
          destFormatting.map((row) => row.slice(-additionalColumns)),
        );
      }
      range.format.horizontalAlignment = "Left";
      range.format.verticalAlignment = "Top";
      range.numberFormat = [["General"]];

      // this.excel.copyBorderStyle(metadata.sheetName, metadata.uploadRange, destSheetName, pasteRange);      -------------- WIP ------------------

      await context.sync();
    });

    // console.log("Formatting successful.");
  }

  async setLastColumnsWidth(context, range, colWidth, numCol) {
    range.load("columnCount");
    await context.sync();

    let startingColumn = Math.max(range.columnCount - numCol, 0);

    for (let i = startingColumn; i < range.columnCount; i++) {
      let columnRange = range.getCell(0, i);
      columnRange.format.columnWidth = colWidth;
    }

    await context.sync();
  }

  async nameExcelRange(sheetName, rangeName, rangeAddress, context, nameScope) {
    let sheet = context.workbook.worksheets.getItem(sheetName);

    if (nameScope === "worksheet") {
      let nameItem = sheet.names.getItemOrNullObject(rangeName);
      let range = sheet.getRange(rangeAddress);

      context.load(nameItem, "isNullObject");
      await context.sync();

      if (nameItem.isNullObject) {
        sheet.names.add(rangeName, range);
        await context.sync();
      } else {
        throw new Error(`Name already assigned in worksheet: ${rangeName}`);
      }
    } else if (nameScope === "workbook") {
      let sheet = context.workbook.worksheets.getItem(sheetName);
      let nameItem = context.workbook.names.getItemOrNullObject(rangeName);
      let range = sheet.getRange(rangeAddress);

      context.load(nameItem, "isNullObject");
      await context.sync();

      if (nameItem.isNullObject) {
        context.workbook.names.add(rangeName, range);
      }
    }
  }

  async nameResultRange(
    rawSheetName,
    sheetName,
    rangeName,
    rangeAddress,
    context,
    nameScope,
    adjustmentId,
  ) {
    // Clear existing names from range
    await this.deleteNamesWithinRange(
      `${rawSheetName}!${rangeAddress}`,
      context,
    );

    await context.sync();

    let sheet = context.workbook.worksheets.getItem(sheetName);

    if (nameScope === "worksheet") {
      let nameItem = sheet.names.getItemOrNullObject(rangeName);
      let range = sheet.getRange(rangeAddress);

      context.load(nameItem, "isNullObject");
      await context.sync();

      if (nameItem.isNullObject) {
        sheet.names.add(rangeName, range, adjustmentId);
      } else {
        throw new Error(`Name already assigned in worksheet: ${rangeName}`);
      }
    } else if (nameScope === "workbook") {
      let sheet = context.workbook.worksheets.getItem(sheetName);
      let nameItem = context.workbook.names.getItemOrNullObject(rangeName);
      let range = sheet.getRange(rangeAddress);

      context.load(nameItem, "isNullObject");
      await context.sync();

      if (nameItem.isNullObject) {
        context.workbook.names.add(rangeName, range, adjustmentId);
      } else {
        throw new Error(`Name already assigned in workbook: ${rangeName}`);
      }
    }
  }

  findArrayById(id, arrayOfArrays) {
    let resultId = id.split("_")[0];
    return arrayOfArrays.find((arr) => arr.id === resultId);
  }

  /**
   * Names the whole table range and first cell based on given tableId and projectId,
   * and each row with respective rowId. Throws error if a duplicate name is found.
   *
   * @async
   * @function nameTableAndRows
   * @param {string} tableId - ID to name the whole table range
   * @throws Will throw an error if a duplicate name is found
   */
  async nameTableAndRows(tableId, tableList, projectId) {
    //    tableId = 'eb3777d899334b65a615c38637b8f5ae'
    // Get constant variables
    const tableData = tableList[tableId].tableContent;
    const metaData = tableList[tableId].metadata;

    const sheetName = metaData.sheetName;
    const tableRange = metaData.uploadRange;
    const firstCell = this.splitExcelCell(tableRange.split(":")[0]);
    const lastCell = this.splitExcelCell(tableRange.split(":")[1]);

    // Retrieve source table formatting or, if error, retrieve TD default table formatting
    await window.Excel.run(async (context) => {
      // Name whole table range
      tableId = `table_${tableId.replace(/-/g, "")}`;
      await this.nameExcelRange(
        sheetName,
        tableId,
        tableRange,
        context,
        "workbook",
      );

      try {
        // Name first cell with projectId
        let project = `project_${projectId.replace(/-/g, "")}`;
        await this.nameExcelRange(
          sheetName,
          project,
          `${firstCell.column}${firstCell.row}`,
          context,
          "worksheet",
        ); // NEED TO IMPROVE ERROR HANDLING HERE
      } catch (error) {
        console.log(error.message);
      }
      // Name each row in the table using rowIds
      const rowIds = tableData.map((row) => row.rowId);
      const startRowIndex = firstCell.row + 1; // excl. header
      for (let i = 0; i < rowIds.length; i++) {
        let rowId = `row_${rowIds[i].replace(/-/g, "")}`;
        let rowIndex = startRowIndex + i;
        let rowRange = `${firstCell.column}${rowIndex}:${lastCell.column}${rowIndex}`;
        await this.nameExcelRange(
          sheetName,
          rowId,
          rowRange,
          context,
          "workbook",
        );
      }
      await context.sync();
    });
  }

  async updateExcelNameAdjustments(tableId, resultId, adjustmentId) {
    await window.Excel.run(async (context) => {
      // getRange object required for working processing -> range must be dollarized for name
      let oldItem = context.workbook.names.getItemOrNullObject(
        `result_${tableId}_${resultId}`,
      );
      let range = oldItem.getRange();
      range.load("address");
      await context.sync();

      await this.deleteNamesWithinRange(range.address, context);

      context.workbook.names.add(
        `result_${tableId}_${resultId}`,
        range,
        adjustmentId,
      ); /// ALIGNMENT NEEDED WITH BACKEND - adjustmentID required
      await context.sync();
    }).catch(console.error);
  }

  async streamResultsOnSubmit(tableId, resultStatus, noOfHeaders) {
    const tableIdCleaned = tableId && tableId.replace(/-/g, "");

    await window.Excel.run(async (context) => {
      for (let rowId in resultStatus) {
        if (rowId !== "tableCommentary") {
          for (let pipeline in resultStatus[rowId]) {
            if (resultStatus[rowId][pipeline]) {
              const typePrefix = settingList.find(
                (setting) => setting.type === pipeline,
              ).typePrefix;
              const nameCommentary = `${typePrefix}${tableIdCleaned}`;
              const rows = resultStatus[rowId][pipeline][0];
              const adjustments = rows.adjustments;
              // queue = rows.queueMethod
              let statusText = "";
              let adjustmentId = null;
              // Determine status text
              if (rows.status === "queued") {
                statusText = "queued...";
              } else if (rows.status === "running") {
                statusText = "running...";
              } else if (rows.status === "finished") {
                if (
                  rows.queueMethod === "lineQuestions" ||
                  rows.queueMethod === "answerLineQuestions"
                ) {
                  statusText = "finished";
                } else if (adjustments && adjustments.length > 0) {
                  statusText =
                    adjustments[adjustments.length - 1]["adjustment"];
                  adjustmentId =
                    adjustments[adjustments.length - 1]["adjustmentId"];
                } else {
                  statusText = rows.response.answer;
                }
              } else if (
                rows.status === "failed" ||
                rows.status === "Finally failed"
              ) {
                statusText = "failed...";
              }
              if (
                !this.oldStatusTracker[rows.id] ||
                statusText !== this.oldStatusTracker[rows.id]
              ) {
                this.oldStatusTracker[rows.id] = statusText;
                let rowIdCleaned = `row_${rowId.replace(/-/g, "")}`;
                let colCommentary = context.workbook.names
                  .getItemOrNullObject(nameCommentary)
                  .getRange();
                let rowCommentary = context.workbook.names
                  .getItemOrNullObject(rowIdCleaned)
                  .getRange();
                colCommentary.load("address");
                rowCommentary.load("address");
                await context.sync();
                let targetSheet = "";
                if (
                  !colCommentary.isNullObject &&
                  !rowCommentary.isNullObject
                ) {
                  let rawTargetSheet = this.getTextBeforeLastExclamation(
                    colCommentary.address,
                  );
                  targetSheet = this.removeQuotes(
                    this.getTextBeforeLastExclamation(colCommentary.address),
                  );
                  let targetColumn = this.splitExcelCell(
                    this.getTextAfterLastExclamation(colCommentary.address),
                  ).column;
                  let targetRow = this.splitExcelCell(
                    this.getTextAfterLastExclamation(
                      rowCommentary.address,
                    ).split(":")[0],
                  ).row;
                  let pasteRange = `${targetColumn}${targetRow + noOfHeaders - 1}`;

                  let sheet = context.workbook.worksheets.getItem(targetSheet);
                  let cellToUpdate = sheet.getRange(pasteRange);
                  cellToUpdate.load("format/font");
                  cellToUpdate.load("values");
                  await context.sync();

                  // If cell already contains latest answer, don't update
                  let currentValues = cellToUpdate.values;
                  if (currentValues.length > 0 && currentValues[0].length > 0) {
                    if (statusText !== currentValues[0][0]) {
                      cellToUpdate.values = [[statusText]];
                      cellToUpdate.format.font.italic = true;
                      if (
                        rows.response &&
                        statusText === rows.response.answer
                      ) {
                        cellToUpdate.format.font.italic = false;
                      }
                      // Name cell with results Id
                      if (
                        rows.status === "finished" &&
                        statusText !== "finished"
                      ) {
                        let resultsId = `result_${tableIdCleaned}_${rows.id}`;
                        await this.nameResultRange(
                          rawTargetSheet,
                          targetSheet,
                          resultsId,
                          pasteRange,
                          context,
                          "workbook",
                          adjustmentId,
                        );
                        await context.sync();
                      }
                    }
                  }
                }
              }
              await context.sync();
            }
          }
        }
      }
    }).catch((error) => {
      console.error(`Error: ${error}`);
    });
  }
}

export default Excel;
