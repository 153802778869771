import Vue from 'vue'
import VueRouter from 'vue-router'
import { route } from './helpers'

Vue.use(VueRouter)


const router = new VueRouter({
  mode: "hash",
  routes: [
    route(
      "/",
      "ProjectListPage",
      "ProjectList",
    ),
    route(
      "/prompts",
      "PromptDetailsPage",
      "PromptDetails",
      true
    ),
    route(
      "/projects/:projectId",
      "ProjectDetailPage",
      "ProjectDetail"
    ),
    route(
      "/projects/:projectId/:tableId",
      "TablePage",
      "Table"
    ),
    route(
      "unmatched",
      "PageNotFound",
      "404"
    ),
  ],
});

export default router;
