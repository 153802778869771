import { HTTP_BRAIN } from "./../../../functions/http-common";
import { Types } from "./../../types";

export const actions = {
  [Types.actions.DO_TNC_CHECK]: async ({ commit }, payload) => {
    return HTTP_BRAIN.post("/signin", payload)
      .then((result) => {
        commit(Types.mutations.SET_TNC_CHECK, result.data.firstTimeAccess);
      })
      .catch((error) => {
        console.error("Error in checking Terms and Conditions:", error);
      });
  },
};
