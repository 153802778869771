import { Types } from "./../../types";

export const mutations = {
  [Types.mutations.SET_LOADING_STATUS](state, payload) {
    state.isLoading = payload;
  },
  [Types.mutations.SET_MODAL_DETAIL]: (state, payload) => {
    state.modal = payload;
  },
  [Types.mutations.SET_PROMPTS](state, payload) {
    state.prompts = payload;
  },
  [Types.mutations.SET_INDIVIDUAL_PROMPT](state, payload) {
    state.individualPrompt = payload;
  },
  [Types.mutations.ADD_NEW_PROMPT]: (state, payload) => {
    state.prompts.push(payload);
  },
  [Types.mutations.SET_SNACKBAR]: (state, payload) => {
    state.snackbar = payload;
  },
};
