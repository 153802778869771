import { Auth } from '../config/msalConfig'
import axios from 'axios';
import CryptoJS from 'crypto-js';

// function debugMode() {
//   const backendUrl = JSON.parse(sessionStorage.getItem('isProdEnvironment')) 
//   ? process.env.VUE_APP_PROD 
//   : process.env.VUE_APP_BACKEND;
//   return backendUrl
// }

function getUrl(backendUrl) {
  let url = ''
  if (backendUrl instanceof Function) {
    url = backendUrl()
  } else url = backendUrl
  return url
}
function createHTTPInstance(url, tokenType) {
  return {
   get (path, data) {
      return call('get', path, data, getUrl(url), tokenType)
    },

    post (path, data) {
      return call('post', path, data, getUrl(url), tokenType)
    },

    delete (path, data) {
      return call('delete', path, data, getUrl(url), tokenType)
    }
  };
}



function getEncryptedValue () {
    const payload = process.env.VUE_APP_PROJECT_NAME
    const base64key = process.env.VUE_APP_Project_Report_Assistant_SECRET

    const derived_key = CryptoJS.enc.Base64.parse(base64key);
    const iv = CryptoJS.lib.WordArray.random(16);
    
    const test = CryptoJS.AES.encrypt(payload, derived_key, { iv: iv, mode: CryptoJS.mode.CBC });
    return {
        ciphertext: test.toString(),
        iv: iv.toString(CryptoJS.enc.Hex),
      }
    }

export const HTTP = createHTTPInstance(process.env.VUE_APP_BACKEND, 'accessToken')
export const HTTP_USER = createHTTPInstance(process.env.VUE_APP_BACKEND, 'accessToken')
export const HTTP_BRAIN = createHTTPInstance(process.env.VUE_APP_BRAIN, 'idToken')
const { ciphertext, iv } = getEncryptedValue()
/**
 * Fetch wrapper
 */
async function call (method, path, data, baseUrl, tokenType ) {
  const token = await Auth.getCachedToken(tokenType)
  let url = ''
  let headers = {
      'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Origin': 'https://localhost:3000'
   }
  if (baseUrl === process.env.VUE_APP_BRAIN) {
    headers = {
      ...headers,
      'project-name': process.env.VUE_APP_PROJECT_NAME,
      'engagement-code': process.env.VUE_APP_ENGAGEMENT_CODE,
      'X-API-version': process.env.VUE_APP_X_API_VERSION,
      'encryption-key': ciphertext,
      'iv': iv
    }
  }
  
   if(path.includes('http')) {
    url = path
   } else {
    url = `${baseUrl}${path}`
   }
  return axios({ method, url, data, headers })
}