<template>
  <v-dialog
    :value="modal.showDialog"
    persistent
    max-width="500"
    max-height="inherit"
    justify="center"
    :retain-focus="false"
    class="overflow-hidden"
  >
  <v-card class="pt-2">
      <v-card-title class="d-flex justify-space-between headline">
        <div class="col-10 text-h6"><slot name="header"></slot></div>
        <div class="col-2">
          <slot name="icon"></slot>
          <v-btn v-if="isClose" icon @click="updateModalDetail({
            showDialog: false,
            name: modal.name,
            errorMessage: '',
            }),
            resetFunction && resetFunction()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        
      </v-card-title>
      <v-card-text class="py-0"><slot name="body"></slot></v-card-text>
      <v-card-actions class="d-block">
        <slot name="footer"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { Types } from "./../store/types";

export default {
  name: "ModalLayout",
  props: {
    modal: {
      required: true,
      type: Object,
    },
    updateModalDetail: {
      required: true,
      type: Function,
    },
    isClose: {
      default: true,
      type: Boolean
    },
    resetFunction: {
      type: Function,
    }
  },
  computed: {
    ...mapGetters("projectDetail", {
      modalDetails: Types.getters.GET_MODAL_DETAIL
    }),
  },
};
</script>
