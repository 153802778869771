import { Types } from "./../../types";
import { resultList } from "../../../data/constant";
import Excel from "../../../functions/excel";

export const mutations = {
  [Types.mutations.SET_LOADING_STATUS]: (state, payload) => {
    state.isLoading = { ...state.isLoading, ...payload };
  },
  [Types.mutations.SET_PROJECT_DETAIL]: (state, payload) => {
    state.projectDetail = payload;
  },
  [Types.mutations.SET_MODAL_DETAIL]: (state, payload) => {
    state.modal = payload;
  },
  [Types.mutations.SET_LOADING_TEXT]: (state, payload) => {
    state.isLoadingText = payload;
  },
  [Types.mutations.SET_TABLES]: (state, payload) => {
    const { data, tableId, projectId, uploadTable, prompts, editMode } = payload;
    if (data.length) {
      if(!editMode) {
        Object.keys(resultList).forEach((type) => {
          state.settings[type] = {
            projectId,
            tableId,
            rowIds: [],
          };
        });
      }
      
      const list = data.reduce((obj, item) => {
        if (obj[item.id] === undefined) {
          obj[item.id] = [];
        }
        obj[item.id] = item;
        return obj;
      }, {});
      state.tables = { ...list };
      if (uploadTable) {
        const excel = new Excel();
        excel.nameTableAndRows(
          data[data.length - 1].id,
          state.tables,
          projectId,
        );
      }
    
      if (tableId) {
        state.tables[tableId].tableContent.forEach((item) => {
          Object.entries(resultList).forEach((entry) => {
            let [key, value] = entry;
            let settingValue = value
            let collectionList = state.projectDetail.collections || [];
            let favoriteCollection =
              (state.projectDetail.collections &&
                state.projectDetail.collections.find(
                  (item) => item.favourite,
                )) ||
              {};
            const defaultPrompts = prompts.find(item => item.id === value.promptIds.userPromptId && item.promptMethod === key) || {}
            if (defaultPrompts && Object.keys(defaultPrompts).length) {
              // eslint-disable-next-line
              const { systemPromptId, additionalContext, ...rest} = defaultPrompts.advancedSettings
              settingValue = rest
            }
            settingValue = {
              ...settingValue,
              promptIds: {
                ...settingValue.promptIds,
                userPromptId: value.promptIds.userPromptId
              }
            }
            const isVectorstoreContext =
              settingValue.contextFlag.length &&
              settingValue.contextFlag.filter(
                (item) => item.name === "vectorstoreContext",
              )?.length;
            if (isVectorstoreContext && collectionList.length) {
              const collectionId = Object.keys(favoriteCollection).length
                ? favoriteCollection.collectionId
                : collectionList[0].collectionId;
              const selectedCollection = state.projectDetail && state.projectDetail.collections && state.projectDetail.collections.find(collection => collection.collectionId === collectionId);
              const selectedFileList = selectedCollection.fileList.map(item => item.fileName)
              const documents = settingValue.vectorstore.tags && settingValue.vectorstore.tags.length
                ? selectedCollection.fileList.filter((file) => this.defaultSetting.vectorstore.tags.some((tag) => file.tags.includes(tag))).map(item => item.fileName)
                : selectedFileList;
              settingValue = {
                ...settingValue,
                vectorstore: {
                  ...settingValue.vectorstore,
                  collectionId,
                  documents
                },
              };
            }
            state.settings = {
              ...state.settings,
              [key]: {
                ...state.settings[key],
                rowIds: [
                  ...state.settings[key].rowIds,
                  {
                    rowId: item.rowId,
                    ...settingValue,
                  },
                ],
              },
            };
          });
        });
        state.selectedTableRow = state.tables[tableId].tableContent;
      }
    } else {
      state.tables = {};
      state.selectedTableRow = [];
    }
  },
  
  [Types.mutations.SET_SNACKBAR]: (state, payload) => {
    state.snackbar = payload;
  },
  [Types.mutations.SET_DOCUMENTS]: (state, payload) => {
    state.documents = payload;
  },
  [Types.mutations.SET_COLLECTIONS]: (state, payload) => {
    state.collections = payload;
  },
  [Types.mutations.SET_SETTINGS]: (state, payload) => {
    state.vectorsStatus = payload;
  },
  [Types.mutations.SET_UPDATE_RESULTS]: (state, payload) => {
    const { tableContent } = payload;
    let list = [];
    let temp = {};
    let latestResponseQnA = {};
    let latestResponseCommentary = {};
    tableContent.forEach((item) => {
      [...state.QnAResults, ...state.commentaryResults].forEach((list) => {
        const type = list.queueMethod;
        if (!temp[list.rowId])
          temp[list.rowId] = { qna: [], lineCommentary: [] };
        else
          temp[list.rowId] = {
            ...temp[list.rowId],
            [type]: [...temp[list.rowId][type], { ...list }],
          };
      });
      if (temp[item.rowId] && temp[item.rowId]["qna"])
        latestResponseQnA =
          temp[item.rowId].qna.length &&
          temp[item.rowId].qna.reduce((a, b) => (a._ts > b._ts ? a : b));
      if (temp[item.rowId] && temp[item.rowId]["lineCommentary"])
        latestResponseCommentary =
          temp[item.rowId].lineCommentary.length &&
          temp[item.rowId].lineCommentary.reduce((a, b) =>
            a._ts > b._ts ? a : b,
          );
      list = [
        ...list,
        {
          ...item,
          QnA:
            latestResponseQnA && latestResponseQnA.response
              ? latestResponseQnA.response.answer
              : "",
          Commentary:
            latestResponseCommentary && latestResponseCommentary.response
              ? latestResponseCommentary.response.answer
              : "",
        },
      ];
    });
    state.pasteTableContent = list;
  },
  [Types.mutations.SET_SELECTED_ROW]: (state, payload) => {
    state.selectedTableRow = payload;
  },
  [Types.mutations.SET_RESULT_STATUS]: (state, payload) => {
    state.resultStatus = payload;
  },
  [Types.mutations.SET_RESULT_LIST]: (state, payload) => {
    let resultList = {};
    let sortedResultList = {};
    Object.keys(payload) &&
      Object.keys(payload).forEach((item) => {
        payload[item].results &&
          payload[item].results.length &&
          payload[item].results.forEach((subItem) => {
            if (!resultList[subItem.queueMethod])
              resultList = {
                ...resultList,
                [subItem.queueMethod]: [],
              };
            resultList = {
              ...resultList,
              [subItem.queueMethod]: [
                ...resultList[subItem.queueMethod],
                subItem,
              ],
            };
          });
      });
    Object.keys(resultList).forEach((item) => {
      sortedResultList = {
        ...sortedResultList,
        [item]: resultList[item].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        ),
      };
    });
    state.resultList = { ...sortedResultList };
  },
    [Types.mutations.SET_TABLE_STATUS]: (state, payload) => {
    let tableStatus = {};
    Object.keys(payload).forEach((item) => {
      payload[item].results.forEach((subItem) => {
        if (item === "tableResults") {
          tableStatus = {
            ...tableStatus,
            ['tableCommentary']: [
              ...(tableStatus["tableCommentary"] || []),
              subItem,
            ],
          };
        } else {
          tableStatus = {
            ...tableStatus,
            [item]: {
              ...(tableStatus[item] || {}),
              [subItem.queueMethod]: [
                ...((tableStatus[item] &&
                  tableStatus[item][subItem.queueMethod]) ||
                  []),
                subItem,
              ],
            },
          };
        }
      });
    });
    Object.keys(tableStatus).forEach((item) => {
      if (item === "tableCommentary") {
        tableStatus = {
          ...tableStatus,
          [item]: tableStatus[item].sort(
            (a, b) => new Date(b.finishedAt) - new Date(a.finishedAt),
          ),
        };
      } else {
        Object.keys(tableStatus[item]).forEach((subItem) => {
          tableStatus = {
            ...tableStatus,
            [item]: {
              ...tableStatus[item],
              [subItem.queueMethod]:
                tableStatus[item][subItem.queueMethod] &&
                tableStatus[item][subItem.queueMethod].sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
                ),
            },
          };
        });
      }
    });
    state.tableStatus = tableStatus;
  },
  [Types.mutations.SET_UPDATE_SETTING]: (state, payload) => {
    const { params, pipeline, rowId, selectAll } = payload;
    let newRows = [];
    if (selectAll) {
      newRows = state.settings[pipeline].rowIds.map((row) => ({
        ...params,
        rowId: row.rowId,
      }));
    } else {
      newRows = state.settings[pipeline].rowIds.map((item) => {
        if (rowId === item.rowId) {
          return params;
        } else return item;
      });
    }
    state.settings = {
      ...state.settings,
      [pipeline]: {
        ...state.settings[pipeline],
        rowIds: [...newRows],
      },
    };
  },

  [Types.mutations.SET_TABLE_RELATIONSHIPS]: (state, relationships) => {
    state.tableRelationships = relationships;
  },
}
