import { HTTP } from "./../../../functions/http-common";
import { Types } from "./../../types";

export const actions = {
  [Types.actions.DO_MODAL_DETAIL]: ({ commit }, payload) => {
    commit(Types.mutations.SET_MODAL_DETAIL, payload);
  },
  [Types.actions.DO_PROJECT_FILTER]: ({ commit }, payload) => {
    let filteredProjectList = [];
    let { searchTerm, isAscending, list } = payload;
    if (searchTerm || isAscending !== undefined) {
      filteredProjectList = list
        .filter((project) =>
          project.projectName.toLowerCase().includes(searchTerm.toLowerCase()),
        )
        .slice()
        .sort((a, b) => {
          let dateA = new Date(a.createdAt);
          let dateB = new Date(b.createdAt);
          return isAscending ? dateA - dateB : dateB - dateA;
        });
    } else filteredProjectList = list;
    commit(Types.mutations.SET_PROJECT_FILTER, filteredProjectList);
  },
  [Types.actions.DO_PROJECT_LIST]: ({ commit, state }) => {
    commit(Types.mutations.SET_LOADING_STATUS, true);
    HTTP.get("/projects")
      .then((result) => {
        commit(Types.mutations.SET_PROJECT_LIST, result.data);
        commit(Types.mutations.SET_LOADING_STATUS, false);
      })
      .catch(() => {
        state.snackbar && state.snackbar.showSnackbar({
          message: "Something went wrong with API request",
          color: "error",
        });
        commit(Types.mutations.SET_LOADING_STATUS, false);
      });
  },
  [Types.actions.DO_CREATE_PROJECT]: ({ dispatch, commit }, payload) => {
    commit(Types.mutations.SET_MODAL_DETAIL, { showDialog: false });
    commit(Types.mutations.SET_LOADING_STATUS, true);
    HTTP.post("/projects", payload).then(
      (response) => {
        dispatch(Types.actions.DO_PROJECT_LIST);
        console.log("Project created successfully", response);
      },
      (error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
        });
        commit(Types.mutations.SET_LOADING_STATUS, false);
        console.log(error);
      },
    );
  },
  [Types.actions.DO_UPDATE_SNACKBAR]: ({ commit }, payload) => {
    commit(Types.mutations.SET_SNACKBAR, payload);
  },
};
