import { HTTP } from "./../../../functions/http-common";
import { Types } from "./../../types";

export const actions = {
  [Types.actions.DO_MODAL_DETAIL]: ({ commit }, payload) => {
    commit(Types.mutations.SET_MODAL_DETAIL, payload);
  },
  [Types.actions.DO_GET_PROMPTS]: async({ commit }, payload) => {     
    commit(Types.mutations.SET_LOADING_STATUS, true);
    await HTTP.post("/retrieve/prompts", payload)
      .then((response) => {
      if (Object.keys(payload).length === 0) {
        commit(Types.mutations.SET_PROMPTS, response.data);
      }
      else {
        commit(Types.mutations.SET_INDIVIDUAL_PROMPT, response.data[0]);
      } commit(Types.mutations.SET_LOADING_STATUS, false);
    })
     .catch((error) => {
      commit(Types.mutations.SET_LOADING_STATUS, false);
      console.log(error);
    });
  },

  [Types.actions.DO_ADD_NEW_PROMPT]: async (
    { state, dispatch, commit },
    payload,
  ) => {
    commit(Types.mutations.SET_LOADING_STATUS, true);
    const { updatedPrompt, isDelete, promptId } = payload
    /* eslint-disable */
    return HTTP.post("/prompt", updatedPrompt)
      .then((response) => {
        if (isDelete) {
          const url = `/projects/prompt?delPrompt=${promptId}`;
          dispatch(Types.actions.DO_DELETE_PROMPT, { url, promptId });
        }
        dispatch(Types.actions.DO_GET_PROMPTS, {});
        commit(Types.mutations.SET_MODAL_DETAIL, { showDialog: false });
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
        });
        commit(Types.mutations.SET_LOADING_STATUS, false);
          state.snackbar.showSnackbar({
          message: "API call failed",
          color: "error",
          icon: "mdi-alert",
        });
        console.log(error);
      });
  },
  [Types.actions.DO_DELETE_PROMPT]: ({ commit, state }, payload) => {
    const { url, promptId } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    return HTTP.delete(url)
      .then((result) => {
        const updatedPrompts = state.prompts.filter(item => item.id !== promptId)
        commit(Types.mutations.SET_PROMPTS, updatedPrompts);
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: 'DeletePromptModal'
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "Something went wrong! Please try again",
          name: 'DeletePromptModal'
        });
        console.log("error", error);
      });
  }, 
  [Types.actions.DO_UPDATE_SNACKBAR]: ({ commit }, payload) => {
    commit(Types.mutations.SET_SNACKBAR, payload);
  },
};
