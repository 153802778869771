const lineQuestions = {
  promptIds: {
    userPromptId: "43a7d21dcfa1424aba41f2fc6f75ee40",
    systemPromptId: "369677a741b943a6af5faf276dce40b6",
  },
  contextFlag: [
    {
      name: "fullTable",
      disable: false,
    },
    {
      name: "relatedContext",
      disable: false,
    },
    {
      name: "includeDescription",
      disable: true,
    },
    {
      name: "userResults",
      disable: false,
    },
  ],
  vectorstore: {
    collectionId: "",
    tags: [],
    documents: [],
    numChunksProvided: "6",
    vectorPromptId: "acdb3316762e41c88791c4c4d00c29e2",
    model_type: "gpt-4",
    temperature: 0.2
  },
  pipelineSettings: {
    model_type: "gpt-4",
    temperature: 0.2,
  },
};

const lineCommentary = {
  promptIds: {
    userPromptId: "6d2a58e4a8a24c708f6f38e91cbe9818",
    systemPromptId: "369677a741b943a6af5faf276dce40b6",
  },
  contextFlag: [
    {
      name: "fullTable",
      disable: false,
    },
    {
      name: "answers",
      disable: false,
    },
    {
      name: "questions",
      disable: false,
    },
    {
      name: "includeDescription",
      disable: true,
    },
    {
      name: "vectorstoreContext",
      disable: false,
    },
    {
      name: "userResults",
      disable: false,
    },
  ],
  vectorstore: {
    collectionId: "",
    tags: [],
    documents: [],
    numChunksProvided: "6",
    vectorPromptId: "acdb3316762e41c88791c4c4d00c29e2",
    model_type: "gpt-4",
    temperature: 0.2
  },
  pipelineSettings: {
    model_type: "gpt-4",
    temperature: 0.2,
  },
};
const tableCommentary = {
  promptIds: {
    userPromptId: "5baf7ea241c040baa8db33946b987c0f",
    systemPromptId: "369677a741b943a6af5faf276dce40b6",
  },
  contextFlag: [
    {
      name: "fullTable",
      disable: false,
    },
    {
      name: "lineCommentary",
      disable: false,
    }, 
    {
      name: "userResults",
      disable: false,
    },
    {
      name: "includeDescription",
      disable: true,
    },
  ],
  vectorstore: {
    collectionId: "",
    tags: [],
    documents: [],
    numChunksProvided: "6",
    vectorPromptId: "acdb3316762e41c88791c4c4d00c29e2",
    model_type: "gpt-4",
    temperature: 0.2
  },
  pipelineSettings: {
    model_type: "gpt-4",
    temperature: 0.2,
  },
};

const lineDescription = {
  promptIds: {
    userPromptId: "6c01c13f52f849098d822d6939d978d0",
    systemPromptId: "369677a741b943a6af5faf276dce40b6",
  },
  contextFlag: [
    {
      name: "userResults",
      disable: false,
    },
  ],
  vectorstore: {
    collectionId: "",
    tags: [],
    documents: [],
    numChunksProvided: "6",
    vectorPromptId: "acdb3316762e41c88791c4c4d00c29e2",
    model_type: "gpt-4",
    temperature: 0.2
  },
  pipelineSettings: {
    model_type: "gpt-4",
    temperature: 0.2,
  },
   mathAgentInput:{
    mathAgentPromptId: "8091123669b2400cb9d10a028fdf8452",
    model_type: "gpt-4",
    temperature: 0.2,
    calcs: ""
  },
};

const answerLineQuestions = {
  promptIds: {
    userPromptId: "582217c44bc8425b842f8bfb266a703c",
    systemPromptId: "369677a741b943a6af5faf276dce40b6",
  },
  contextFlag: [
    {
      name: "fullTable",
      disable: false,
    },
    {
      name: "questions",
      disable: true,
    },
    {
      name: "relatedContext",
      disable: false,
    },
    {
      name: "vectorstoreContext",
      disable: false,
    },
    {
      name: "userResults",
      disable: false,
    },
    {
      name: "includeDescription",
      disable: true,
    },
  ],
  vectorstore: {
    collectionId: "",
    tags: [],
    documents: [],
    numChunksProvided: "6",
    vectorPromptId: "acdb3316762e41c88791c4c4d00c29e2",
    model_type: "gpt-4",
    temperature: 0.2
  },
  pipelineSettings: {
    model_type: "gpt-4",
    temperature: 0.2,
  },
};

export const resultList = {
  lineDescription,
  lineQuestions,
  answerLineQuestions,
  lineCommentary,
  tableCommentary,
};

export const settingList = [
  {
    description: "Pipeline for Line Description",
    type: "lineDescription",
    label: "Line Description",
    shortTerm: "Description",
    typePrefix: "_D_"
  },
  {
    description: "Pipeline for Line Questions",
    type: "lineQuestions",
    label: "Line Question",
    shortTerm: "Q&A",
    typePrefix: "_Q_"
  },
  {
    description: "Pipeline for Answer Line Questions",
    type: "answerLineQuestions",
    label: "Line Answer",
    shortTerm: "Answer",
    typePrefix: "_A_"
  },
  {
    description: "Pipeline for Line Commentary",
    type: "lineCommentary",
    label: "Line Commentary",
    shortTerm: "Commentary",
    typePrefix: "_C_"
  },
  {
    description: "Pipeline for Table Commentary",
    type: "tableCommentary",
    label: "Table Commentary",
    shortTerm: "Summary",
    typePrefix: "_S_"
  },
];

export const headers = [
  { text: "Row", value: "RowHeader", sortable: false, align: "left" },
  { text: "", value: "", sortable: false, align: "left" },
  { text: "", value: "", sortable: false, align: "left" },
];

export const contextFlag = {
  lineQuestions: [
    {
      name: "fullTable",
      disable: false,
    },
    {
      name: "vectorstoreContext",
      disable: false,
    },
    {
      name: "questions",
      disable: true,
    },
    {
      name: "relatedContext",
      disable: false,
    },
    {
      name: "answers",
      disable: true,
    },
    {
      name: "includeDescription",
      disable: true,
    },
    {
      name: "userResults",
      disable: false,
    },
  ],
  lineCommentary: [
    {
      name: "fullTable",
      disable: false,
    },
    {
      name: "vectorstoreContext",
      disable: false,
    },
    {
      name: "questions",
      disable: false,
    },
    {
      name: "relatedContext",
      disable: false,
    },
    {
      name: "answers",
      disable: false,
    },
    {
      name: "includeDescription",
      disable: true,
    },
    {
      name: "userResults",
      disable: false,
    },
  ],
  summary: [
    {
      name: "fullTable",
      disable: false,
    },
    {
      name: "vectorstoreContext",
      disable: false,
    },
    {
      name: "questions",
      disable: false,
    },
    {
      name: "relatedContext",
      disable: false,
    },
    {
      name: "answers",
      disable: false,
    },
    {
      name: "includeDescription",
      disable: true,
    },
    {
      name: "userResults",
      disable: false,
    },
  ],
  lineDescription: [
    {
      name: "fullTable",
      disable: false,
    },
    {
      name: "vectorstoreContext",
      disable: false,
    },
    {
      name: "questions",
      disable: false,
    },
    {
      name: "relatedContext",
      disable: false,
    },
    {
      name: "answers",
      disable: false,
    },
    {
      name: "includeDescription",
      disable: false,
    },
    {
      name: "userResults",
      disable: false,
    },
  ],
  answerLineQuestions: [
    {
      name: "fullTable",
      disable: false,
    },
    {
      name: "vectorstoreContext",
      disable: false,
    },
    {
      name: "questions",
      disable: true,
    },
    {
      name: "relatedContext",
      disable: false,
    },
    {
      name: "answers",
      disable: true,
    },
    {
      name: "includeDescription",
      disable: true,
    },
    {
      name: "userResults",
      disable: false,
    },
  ],
  tableCommentary: [
    {
      name: "fullTable",
      disable: false,
    },
    {
      name: "vectorstoreContext",
      disable: false,
    },
    {
      name: "questions",
      disable: false,
    },
    {
      name: "relatedContext",
      disable: false,
    },
    {
      name: "answers",
      disable: false,
    },
    {
      name: "includeDescription",
      disable: true,
    },
    {
      name: "lineCommentary",
      disable: false,
    },
    {
      name: "userResults",
      disable: false,
    },
  ],
};

export const contextFlagDictionary = {
  'fullTable': "Complete Excel Table",
  'vectorstoreContext': "Documents",
  'questions': "Q&A - Questions",
  'relatedContext': "linked Tables",
  'answers': "Q&A - Answers",
  'includeDescription': "Description",
  'userResults': "Management Answers",
  'lineCommentary': "Commentary"
}

export const pasteTypes = ["newSheet", "nextTable", "selectedRange"];

export const nextTableConfig = {
  selection: {
    lineDescription: true,
    lineQuestions: true,
    answerLineQuestions: true,
    lineCommentary: true,
    summary: false,
  },
  method: "nextTable",
}

export const qnaConfig = {
  selection: {
    lineDescription: false,
    lineQuestions: true,
    answerLineQuestions: true,
    lineCommentary: false,
    summary: false,
    managementAnswers: true
  },
  method: "qnaSheet",
}

export const namePrefixes = ["result_", "table_", "project_", "row_", "_D_", "_Q_", "_A_", "_C_"]

export const tableStatements = [
  { nameShort: "PL - Overview", category: "PL", subtype: "Overview" },
  { nameShort: "PL - Revenue", category: "PL", subtype: "Revenue" },
  { nameShort: "PL - Gross margin", category: "PL", subtype: "Gross margin" },
  { nameShort: "PL - COGS", category: "PL", subtype: "COGS" },
  { nameShort: "PL - OPEX", category: "PL", subtype: "OPEX" },
  { nameShort: "PL - PEX", category: "PL", subtype: "PEX" },
  { nameShort: "PL - Other Income / Expense", category: "PL", subtype: "Other Income / Expense" },
  { nameShort: "PL - EBITDA", category: "PL", subtype: "EBITDA" },
  { nameShort: "PL - Below EBITDA", category: "PL", subtype: "Below EBITDA" },
  { nameShort: "PL - Adjusted EBITDA", category: "PL", subtype: "Adjusted EBITDA" },
  { nameShort: "PL - Seasonality", category: "PL", subtype: "Seasonality" },
  { nameShort: "PL - CC", category: "PL", subtype: "CC" },
  { nameShort: "PL - Restructuring", category: "PL", subtype: "Restructuring" },
  { nameShort: "PL - Other", category: "PL", subtype: "Other" },
  { nameShort: "Trading - Revenue", category: "Trading", subtype: "Revenue" },
  { nameShort: "Trading - Gross margin", category: "Trading", subtype: "Gross margin" },
  { nameShort: "Trading - Top customers", category: "Trading", subtype: "Top customers" },
  { nameShort: "Trading - Top suppliers", category: "Trading", subtype: "Top suppliers" },
  { nameShort: "Trading - ARR", category: "Trading", subtype: "ARR" },
  { nameShort: "Trading - Cohort", category: "Trading", subtype: "Cohort" },
  { nameShort: "Trading - Life-Time-Value", category: "Trading", subtype: "Life-Time-Value" },
  { nameShort: "Trading - Other", category: "Trading", subtype: "Other" },
  { nameShort: "CT - PY", category: "CT", subtype: "PY" },
  { nameShort: "CT - FY Outturn", category: "CT", subtype: "FY Outturn" },
  { nameShort: "CT - Order Intake / Backlog", category: "CT", subtype: "Order Intake / Backlog" },
  { nameShort: "CT - Run Rate", category: "CT", subtype: "Run Rate" },
  { nameShort: "CT - Other", category: "CT", subtype: "Other" },
  { nameShort: "BS - Overview", category: "BS", subtype: "Overview" },
  { nameShort: "BS - Fixed Assets", category: "BS", subtype: "Fixed Assets" },
  { nameShort: "BS - NWC Overview", category: "BS", subtype: "NWC Overview" },
  { nameShort: "BS - Adjusted NWC", category: "BS", subtype: "Adjusted NWC" },
  { nameShort: "BS - Monthly NWC", category: "BS", subtype: "Monthly NWC" },
  { nameShort: "BS - Funding NWC", category: "BS", subtype: "Funding NWC" },
  { nameShort: "BS - Inventory", category: "BS", subtype: "Inventory" },
  { nameShort: "BS - Inventory Ageing", category: "BS", subtype: "Inventory Ageing" },
  { nameShort: "BS - TAR Customer", category: "BS", subtype: "TAR Customer" },
  { nameShort: "BS - TAP Supplier", category: "BS", subtype: "TAP Supplier" },
  { nameShort: "BS - TAR Ageing", category: "BS", subtype: "TAR Ageing" },
  { nameShort: "BS - TAP Ageing", category: "BS", subtype: "TAP Ageing" },
  { nameShort: "BS - Other Assets", category: "BS", subtype: "Other Assets" },
  { nameShort: "BS - Other Liabilities", category: "BS", subtype: "Other Liabilities" },
  { nameShort: "BS - Provisions", category: "BS", subtype: "Provisions" },
  { nameShort: "BS - Pension Overview", category: "BS", subtype: "Pension Overview" },
  { nameShort: "BS - Deferred Revenue", category: "BS", subtype: "Deferred Revenue" },
  { nameShort: "BS - Net Financial Debt", category: "BS", subtype: "Net Financial Debt" },
  { nameShort: "BS - DLI", category: "BS", subtype: "DLI" },
  { nameShort: "BS - Off BS", category: "BS", subtype: "Off BS" },
  { nameShort: "BS - Other", category: "BS", subtype: "Other" },
  { nameShort: "CF - Overview", category: "CF", subtype: "Overview" },
  { nameShort: "CF - CAPEX", category: "CF", subtype: "CAPEX" },
  { nameShort: "CF - Intra-Year/Month", category: "CF", subtype: "Intra-Year/Month" },
  { nameShort: "CF - Other", category: "CF", subtype: "Other" },
  { nameShort: "Other", category: "Other", subtype: "Other" },
]

export const extractionPipeLineList = [
  'lineQuestions',
  'answerLineQuestions'
]

export const acceptableTypes = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
  "text/csv", // csv
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
  "application/vnd.ms-excel.sheet.macroenabled.12", // xlsm
  "image/jpeg", // jpg
  "image/png", // png
  "image/heic", // heic
  "image/tiff", // tiff          
]

export const modelType = [
  {
    text: 'gpt-4',
    value:'gpt-4'
  },
  {
    text: 'gpt-35-turbo',
    value: 'gpt-35-turbo',
  },
  {
    text: 'gpt-4o',
    value: 'gpt-4o',
  },
  {
    text: 'gpt-4-32k',
    value: 'gpt-4-32k',
    disabled: true,
  },
  {
    text: 'gpt-35-turbo-16k',
    value: 'gpt-35-turbo-16k',
    disabled: true
  }
]

