import {
  PublicClientApplication
} from '@azure/msal-browser'
import { reactive } from 'vue'
export const msalConfig = {
  // required
  auth: {
    // must match info in dashboard
    clientId: process.env.VUE_APP_CLIENTID,
    authority: process.env.VUE_APP_AUTHORITY,
    redirectUri: '/',
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  graph: {
    callAfterInit: true,
    endpoints: {
      people: "/me/people",
      organization: "/organization",
      me: '/me?$select=companyName,department,country',
      manager: '/me/manager',
      profile: '/me',
      photo: { url: "/me/photo/$value", responseType: "blob", force: true },
    }
  },
   system: {
      tokenRenewalOffsetSeconds: 3600 // access token expires after ~83 minutes (5000 seconds) and id token expires after 25 minutes (1500 seconds). We now renew after ~24 minutes (1400 seconds) which leads to an offset of 3600 seconds (60 minuts)
    },
}

/**
 * MSAL instance
 */
export const msalInstance =  new PublicClientApplication(msalConfig);
export const loginRequest = {
    scopes: ['User.Read']
};

export const state = reactive({
  isAuthenticated: false,
  user: null,
})

/**
 * Auth service
 */
export const Auth = {
  
  /**
   * Get token for api
   */
  async getCachedToken (tokenType) {
    const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig);
    await msalInstance.initialize();
    let error = ''
    const redirectResponse = await msalInstance.handleRedirectPromise();
    if (redirectResponse !== null) {
        let accessToken = redirectResponse.accessToken;
        var idToken = redirectResponse.idToken;
        sessionStorage.setItem('ad-accessToken', 'Bearer ' + accessToken);
        sessionStorage.setItem('ad-idToken', idToken);
        sessionStorage.setItem('accesstoken', accessToken);
        sessionStorage.setItem('idToken', idToken);
        sessionStorage.setItem('account', redirectResponse.account);
        error = ''
        return tokenType === 'accessToken' ? accessToken : idToken;
    } else {
        const account = msalInstance.getAllAccounts()[0];
        const accessTokenRequest = {
          scopes: loginRequest.scopes,
          account,
        };
    
        const idToken = await msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then(function (accessTokenResponse) {
            let accessToken = accessTokenResponse.accessToken;
            var idToken = accessTokenResponse.idToken;
            sessionStorage.setItem('ad-accessToken', 'Bearer ' + accessToken);
            sessionStorage.setItem('ad-idToken', idToken);
            sessionStorage.setItem('accesstoken', accessToken);
            sessionStorage.setItem('idToken', idToken);
            error = ''
            return tokenType === 'accessToken' ? accessToken : idToken;
        })
        .catch(function (err) {
          //Acquire token silent failure, and send an interactive request
          console.log("Not able to get token - interactive request");
          error = err
        });
         if (error) {
            await msalInstance.acquireTokenRedirect(accessTokenRequest);
          }
          return idToken
    }
  },

  async getProfilePic() {

    const accessToken = sessionStorage.getItem('accesstoken');

    if (accessToken) {
        fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken

            })
        })
            .then(async (img) => {
                if (img.status == 401) {
                    console.log('un auth DP');
                }
                else if (img.status == 200) {
                    var blob = await img.blob();
                    var profilePicUrl = URL.createObjectURL(blob);
                    localStorage.setItem('UserProfilePic', profilePicUrl);
                    console.log('profile pic url : ' + profilePicUrl);
                }
            });
    }

},
}