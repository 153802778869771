import { Types } from "./../../types";
export const getters = {
  [Types.getters.GET_LOADING_STATUS]: (state) => {
    return state.isLoading;
  },
  [Types.getters.GET_PROJECT_DETAIL]: (state) => {
    return state.projectDetail;
  },
  [Types.getters.GET_MODAL_DETAIL]: (state) => {
    return state.modal;
  },
  [Types.getters.GET_TABLES]: (state) => {
    return state.tables;
  },
  [Types.getters.GET_TABLE_STATUS]: (state) => {
    return state.tableStatus;
  },
  [Types.getters.GET_RESULTS]: (state) => {
    return state.results;
  },
  [Types.getters.GET_PASTE_TABLE_CONTENT]: (state) => {
    return state.pasteTableContent;
  },
  [Types.getters.GET_DOCUMENTS]: (state) => {
    return state.documents;
  },

  [Types.getters.GET_COLLECTIONS]: (state) => {
    return state.collections;
  },

  [Types.getters.GET_SELECTED_ROW]: (state) => {
    return state.selectedTableRow;
  },
  [Types.getters.GET_SETTINGS]: (state) => {
    return state.settings;
  },
  [Types.getters.GET_RESULT_LIST]: (state) => {
    return state.resultList;
  },
  [Types.getters.GET_RESULT_STATUS]: (state) => {
    return state.resultStatus;
  },
  [Types.getters.GET_LOADING_TEXT]: (state) => {
    return state.isLoadingText;
  },

};
