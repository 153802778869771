/**
 * Create component route
 */
export function route (path, name, view, props) {
  return {
    path,
    name,
    component: () => import(`../views/${view}Page.vue`),
    props
  }
}