<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        color="gray03"
        light
        size="40"
        v-bind="attrs"
        class="ma-2"
        v-on="on"
      >
        <span text color="offwhite" class="text-h6">{{ getUserInitials() }}</span>
      </v-avatar>
    </template>
    <v-list color="offwhite" light width="200" dense>
      <v-list-item>
        <v-list-item-content
          v-if="userInfo && userInfo.me && userInfo.me.displayName != ''"
        >
          <v-list-item-title>{{ userInfo.me.displayName }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ userInfo.me.jobTitle }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="!!userInfo.me.department"
            @click="pasteToken()"
          >
            {{ userInfo.me.department }}
          </v-list-item-subtitle>
          <v-list-item-subtitle @click="testToken()">
            {{ userInfo.me.country }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title>Not signed in</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item v-if="admin">
        <v-list-item-content>
          <v-list-item-title>Switch Environment</v-list-item-title>
          <v-list-item-subtitle class="mt-1">
            <a target="_self" href="https://sean-uat.ey.com" v-if="isProd">
              <v-chip class="cursor_icon" >
                <small>Switch to UAT</small>
              </v-chip>
            </a>
            <a target="_self" href="https://sean.ey.com" v-else>
              <v-chip class="cursor_icon">
                <small>Switch to Production</small>
              </v-chip>
            </a>
          </v-list-item-subtitle>
          <!-- <v-list-item-title>Debug Backend Mode</v-list-item-title>
          <v-list-item-subtitle class="cursor_icon mt-1" @click="handleDebugMode">
            <v-icon class="mr-1" small :color="debugMode ? 'green' : 'red'">
              {{ debugMode ? 'mdi-check-circle' : 'mdi-cancel' }}
            </v-icon>
            {{ debugMode ? 'Active' : 'Inactive' }}
          </v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-divider />
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="cursor_icon" @click="handleLogout">Sign out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { Auth, msalConfig, state } from './../config/msalConfig'
import { useAuth } from "./../config/useAuth"

export default {
  name: "AuthComponent",
  data() {
    return {
      contentreply: "nothing",
      badgecolor: "gray03",
      accTokenCopy: "",
      userImage: "",
      userInfo: {},
      msalConfig,
      state
    };
  },
  props: {
    handleLogout: {
      type: Function,
      required: true
    },
    // debugMode: {
    //   type: Boolean,
    //   required: true
    // },
    isProd: {
      type: Boolean,
      required: true
    },
    // handleDebugMode: {
    //   type: Function,
    //   required: true
    // },
    handleEnvironments: {
      type: Function,
      required: true
    },
    admin: {
      type: Boolean,
      required: true
    }
  },
  async mounted() {
    const { getUserList } = useAuth()
    await getUserList()
    this.getUserInfo()
    this.accessToken =  await Auth.getCachedToken('accessToken')
  },
  methods: { 
    getUserInfo() {
      if(Object.keys(this.state.user)) {
        this.userInfo = {
          photoUrl: "",
          me: {
            department: "",
            country: "",
          },
          manager: {
            id: "",
          },
          name: "Hi, sign in...",
          userName: "",
          profile: {
            jobTitle: "",
            officeLocation: "",
            businessPhones: [],
            displayName: "",
            givenName: "",
            surname: "",
            preferredLanguage: "",
            id: "",
          },
        };
        if (this.state.user && this.state.user.me) {
          this.userInfo.profile = this.msalConfig.graph.endpoints.profile;
          this.userInfo.manager = this.msalConfig.graph.endpoints.manager;
          this.userInfo = {
            ...this.userInfo,
            me: this.state.user.me
          }
        }
      } else {
        this.userInfo = {
          photoUrl: "",
          me: {
            department: "",
            country: "",
          },
          manager: {
            id: "",
          },
          name: "Hi, sign in...",
          userName: "",
          profile: {
            jobTitle: "",
            officeLocation: "",
            businessPhones: [],
            displayName: "",
            givenName: "",
            surname: "",
            preferredLanguage: "",
            id: "",
          },
        };
      }
    },  
    async testToken() {
      let accessToken = await window.Office.auth.getAccessToken({
        allowSignInPrompt: true,
        allowConsentPrompt: true,
        forMSGraphAccess: true,
      });
      console.log(accessToken);
    },
    pasteToken() {
      window.Excel.run(async (context) => {
        var rangeAddress = "A1:B4";
        var range = context.workbook.worksheets
          .getActiveWorksheet()
          .getRange(rangeAddress);
        range.load("values");
        await context.sync();
        if (range.values.findIndex((element) => element[0] != "") == -1) {
          if (range.values.findIndex((element) => element[1] != "") == -1) {
            console.log("pasting");
            range.values = [
              ["User", this.state.user.me.displayName],
              ["Department", this.state.user.me.department],
              ["Token", this.accessToken],
              ["Backend", this.$backend],
            ];
          }
        }
        await context.sync();
      });
    },
    getUserInitials() {
      let name = this.state.user && this.state.user.me && this.state.user.me.displayName
      if (name === '') {
        name = ':'
      } else {
        name = name && name.toUpperCase().split(' ').map(item => item.substring(0, 1)).join('').substring(0,2)
      }
      return name
    }
  },
};
</script>

<style>
.cursor_icon {
  cursor: pointer;
}
</style>
