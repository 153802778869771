export const state = () => ({
  isLoading: false,
  prompts: [],
  individualPrompt: [],
  modal: {
    showDialog: false,
    errorMessage: "",
  },
  snackbar: {},
});
