import Vue from "vue";
import App from './App.vue'
import store from "./store";
import router from "./functions/router";
import vuetify from './plugins/vuetify'

// create app
window.Office.onReady(() => {
  new Vue({
    render: h => h(App),
    router,
    store,
    vuetify
  }).$mount("#app");
})
